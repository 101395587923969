  <section class="flex flex-col justify-between">
    <header class="bg-gray-300 w-100 p-4 flex justify-center">
      <img src="/assets/img/logo.png" alt="logo" class="h-8">
    </header>
  
    <ng-container >
      <div class="flex items-center flex-col">
        <p class="text-gray-500 dark:text-gray-900 text-4xl text-center pt-2">Benvenuto</p>
        <p class="text-gray-500 dark:text-gray-400 text-2xl text-center pt-3">Inserisci il tuo identificativo per accedere all'app Pos</p>
      <div class="flex flex-col justify-between items-center gap-2 w-full pt-24">
        <div class="relative w-full pl-10 pr-10">        
          <input [(ngModel)]="telephone" [ngModelOptions]="{standalone: true}" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Inserire identificativo" required>
       </div>
      </div>
      <div class="flex items-center flex-col w-full pl-10 pr-10 pt-10">
      <button class="rounded-full bg-gray-200  w-full py-4 px-6 m-4" [disabled]="!telephone || telephone.length < 1" (click)="login()">
        <span class="uppercase text-xl">
          Login
        </span>
      </button>
    </div>
  
    </div>
    </ng-container>
  </section>
  