import { ErrorHandlerService } from './../../services/error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, map, throwError } from 'rxjs';
import { STORAGE } from 'src/app/models/enum/general';
import { ConsumeRes } from 'src/app/models/interfaces/consume';
import { ISettings } from 'src/app/models/interfaces/setting';
import { Voucher } from 'src/app/models/interfaces/voucher';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetailService {
  setting: ISettings = {};
  storage = STORAGE;
  constructor(private http: HttpClient, private localStorageService: StorageService, private spinner: NgxSpinnerService, private errorHandler: ErrorHandlerService) {
   }

  consumeVoucher(qrCode : string): Observable<ConsumeRes> {
      return this.http.put<ConsumeRes>(environment.api.consumeqrcode, {qrCode: qrCode ? qrCode : ''}).pipe(map((response: ConsumeRes | any) => {
        return response;
      }))
  }

  detail(qrCode: string): Observable<Voucher> {
    this.spinner.show();
    return this.http.post<Voucher>(environment.api.detail, { qrCode: qrCode ? qrCode : '' }).pipe(
      map((response: Voucher | any) => {
        if (response && response.extendedVoucherData && response.extendedVoucherData.amount) {
          response.extendedVoucherData.amount = Math.trunc(response.extendedVoucherData.amount / 100);
        }
        this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
        this.setting.extendedVoucherData = {
          amount: response.extendedVoucherData?.amount ? response.extendedVoucherData?.amount : '',
          currency: response.extendedVoucherData?.currency ? response.extendedVoucherData?.currency : '',
          description: response.extendedVoucherData?.description ? response.extendedVoucherData?.description : '',
          expiry: response.extendedVoucherData?.expiry ? response.extendedVoucherData?.expiry : '',
        }
        this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
        this.spinner.hide();
        return response;
      }),
      catchError((error: any) => {
        this.spinner.hide();
        this.errorHandler.errorHandler(error);
        // You can throw a custom error or return a default value
        // For example:
        return throwError(() => error);
      })
    );
  }
}
