<ng-toast ></ng-toast>
<ngx-spinner
  [fullScreen]="true"
  bdColor="rgba(51,51,51,0.8)"
  color="#fff"
  size="medium"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<section class="flex flex-col min-h-screen">

  <main class="bg-gray-50 flex-1 flex flex-col">
    <router-outlet></router-outlet>
  </main>
</section>
