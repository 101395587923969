import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  constructor(private http: HttpClient) { }

  print(receipt : any): Observable<any> {
    return this.http.post<any>(environment.api.print, receipt).pipe(map((response: any) => {
      return response;
    }))
}
}
