
<section class="flex flex-col items-center p-8 max-w-xl mx-auto">
<div class="text-center">
  <h1 class="text-4xl"><strong>Operazione Conclusa</strong></h1>
  <h2 class="text-2xl"> Buono speso ed estinto con successo</h2>
</div>
<div class="py-12">
  <div class="-rotate-12">
    <div class="rounded-full border-solid border-gray-300 border-4 p-4 h-32 w-32">
      <fa-icon icon="thumbs-up" class="text-8xl text-gray-300 -mb-7 -ml-4"></fa-icon>
    </div>
  </div>
</div>
<div class="flex justify-between w-full py-8">
  <span class="text-2xl">Valore Buono</span>
  <p class="text-2xl">{{ amount | currency : currency?.toUpperCase() }}</p>
</div>
<div class="flex flex-col items-stretch w-full">
  <button class="rounded-full bg-gray-100 py-4 px-6 mb-4 border-2 border-gray-600"  (click)="printReceipt()">
    <fa-icon icon="print" class="text-gray-600 text-xl "></fa-icon>
    <span class="uppercase text-xl">
      Stampa
    </span>
  </button>
  <button class="rounded-full bg-gray-100 py-4 px-6 mb-4 border-2 border-gray-600 ">
    <span class="uppercase text-xl">
      Apri ricevuta
    </span>
  </button>
  <a class="rounded-full bg-gray-200 py-4 px-6 mb-4 text-center" [routerLink]="['/home']">
    <span class="uppercase text-xl">
      chiudi
    </span>
  </a>

</div>
</section>


