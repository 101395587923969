import { Injectable } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor( private toast: NgToastService) { }
  /**
   * @memberof ErrorHandlerService
   * @param {any} error - The error object received from an HTTP request.
   * @returns {void}
   * @description Error handler function that processes HTTP errors and displays error messages using a toast notification.
   * If the error status is not 200 and the response contains a valid error message, it displays the error message
   */
  errorHandler = (error: any) => {
    if (error && error.status !== 200 && error.error && error.error.message) {
      this.toast.error({detail:"Error",position: "topLeft", summary:error.error.message,duration: 3000});
    }
    if (error && error.status !== 200 && error.error && error.message) {
      this.toast.error({detail:"Error",position: "topLeft", summary:error.message,duration: 3000});
    }
  }
}
