import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DetailComponent } from './detail/detail.component';
import { ResultComponent } from './result/result.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faCoffee, faImage, faPrint, faQrcode, faShop, faStore, faThumbsUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { SigninComponent } from './signin/signin.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChallangeTokenComponent } from './challange-token/challange-token/challange-token.component';
import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { NgToastModule } from 'ng-angular-popup';
const iconsList = [
  faXmark,
  faImage,
  faStore,
  faArrowLeft,
  faThumbsUp,
  faPrint,
  faQrcode,
]
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetailComponent,
    ResultComponent,
    SigninComponent,
    ChallangeTokenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ZXingScannerModule,
    FontAwesomeModule,
    HttpClientModule,
    NgxSpinnerModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    NgToastModule
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    library: FaIconLibrary
  ) {
    library.addIcons(...iconsList)
  }
 }
