export const environment = {
    production: false,
    api: {
      signin: '/api/account/login',
      consumeqrcode: '/api/voucher',
      redeem: '/api/voucher/:voucherKey/redeem',
      detail: '/api/voucher/qrcode',
      print: 'http://localhost:8100/printer/line'
    },
    loggerServiceActive: true,
    firebaseConfig : {
      apiKey: "AIzaSyBXOnb-I1VwXpHpGdQ6XmD56dvdy2yfWxU",
      authDomain: "fp8-ledger-dev.firebaseapp.com",
      projectId: "fp8-ledger-dev",
      storageBucket: "fp8-ledger-dev.firebasestorage.app",
      messagingSenderId: "207320605821",
      appId: "1:207320605821:web:2c6454a42e1f19c46e7d14",
      measurementId: "G-X7DN1GZJZS"
      }
  };