import { Observable } from 'rxjs';
import { Component, DestroyRef, inject } from '@angular/core';
import { Voucher } from '../models/interfaces/voucher';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherService } from '../services/voucher.service';
import { ISettings } from '../models/interfaces/setting';
import { STORAGE } from '../models/enum/general';
import { StorageService } from '../services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DetailService } from './services/detail.service';
import { ConsumeRes } from '../models/interfaces/consume';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlerService } from '../services/error-handler.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

  voucher$: Observable<Voucher> | null = null;
  setting: ISettings = {};
  destroyRef = inject(DestroyRef);
  storage = STORAGE;

  constructor(
    private router: Router,
    private localStorageService: StorageService,
    private detailService: DetailService,
    private spinner: NgxSpinnerService,
    private errorHandler: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
    this.voucher$ = this.detailService.detail(this.setting?.qrCode || '');
  }

  handleVoucherUse(id:string) {
    this.consume();
    //this.router.navigate([`/result/${id}`], {queryParams: {amount: amount, currency: currency}});
  }

  consume = () => {
      this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
      if(this.setting) {
        this.spinner.show();
        this.detailService.consumeVoucher(this.setting.qrCode!).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this)
        });
      } else {
       
        // this.router.navigateByUrl('home');
      }
  }

  handleissuerRes = (consumeRes: ConsumeRes) => {
    this.setting.extendedVoucherData ? this.setting.extendedVoucherData.txId = consumeRes?.txId : '';
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
    this.spinner.hide();
    this.router.navigate([`/result/123`]);
  }

  handleIssuerError = (error: any) => {
    this.errorHandler.errorHandler(error);
    this.spinner.hide();
  }
}
