import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeFormat, Result } from '@zxing/library';
import { ISettings } from '../models/interfaces/setting';
import { StorageService } from '../services/storage.service';
import { STORAGE } from '../models/enum/general';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  scannerEnabled = false;
  scannerReady = false;
  enabledFormats = [BarcodeFormat.QR_CODE];
  setting: ISettings = {};
  storage = STORAGE;

  constructor(
    private router: Router,
    private localStorageService: StorageService
  ) {

  }

  toggleScanner(){
    this.scannerEnabled = !this.scannerEnabled;
    if(!this.scannerReady){
      this.scannerReady = false;
    }
  }
  // todo provide a control on success
  scanCompleteHandler($event: Result) {
    //find data to pass for detail page
  }

  scanErrorHandler($event: Error) {
    alert($event.message);
  }
  scanSuccessHandler($event: string) {
    console.log($event)
    $event &&  this.handleDetail($event);
  }
  camerasNotFoundHandler($event: any) {
    alert('No cameras found');
  }
  camerasFoundHandler($event: MediaDeviceInfo[]) {
    $event && (this.scannerReady = true);
  }
  handleDetail($event: string){
    let obj = JSON.parse($event);
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
    this.setting.qrCode = obj.qrCode;
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
    this.router.navigate([`detail/123`]);
  }

}
