import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ResultService } from './services/result.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from '../services/storage.service';
import { ISettings } from '../models/interfaces/setting';
import { STORAGE } from '../models/enum/general';
import { Utils, receipt } from '../utils/utils';
import { ErrorHandlerService } from '../services/error-handler.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {
  amount: string = ''; 
  currency: string = ''; 
  destroyRef = inject(DestroyRef);
  setting: ISettings = {};
  storage = STORAGE;
  receiptToPrint = _.cloneDeep(receipt);
  
  constructor(
    private route: ActivatedRoute,
    private resultService: ResultService,
    private localStorageService: StorageService,
    private spinner: NgxSpinnerService,
    private errorHandler: ErrorHandlerService
  ) { 
    this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
    const currentDate = new Date(); // Get the current date and time
    const date = Utils.formatDate(currentDate); // Generate the formatted date string
    this.amount = this.setting.extendedVoucherData?.amount ? this.setting.extendedVoucherData?.amount.toString() : '0';
    this.currency = this.setting.extendedVoucherData?.currency ? this.setting.extendedVoucherData?.currency : '€';
    const amount =  Utils.formatAmount(this.setting.extendedVoucherData?.amount ? this.setting.extendedVoucherData?.amount.toString() : '0,00');
    this.receiptToPrint.data[15].value = Utils.interpolationTemplate(this.receiptToPrint.data[15].value, {date: date});
    this.receiptToPrint.data[24].value = Utils.formatRow(Utils.interpolationTemplate(this.receiptToPrint.data[24].value, {description: this.setting.extendedVoucherData?.description ? this.setting.extendedVoucherData?.description : ''}), amount);
    const cashToPay = this.setting.extendedVoucherData?.amount ? 100 - +this.setting.extendedVoucherData?.amount  : 100;
    this.receiptToPrint.data[25].value = Utils.formatRow(this.receiptToPrint.data[25].value?.toString()!, Utils.formatAmount (cashToPay.toString()));
    this.receiptToPrint.data[33].value = Utils.interpolationTemplate(this.receiptToPrint.data[33].value, {txId: this.setting.extendedVoucherData?.txId ? this.setting.extendedVoucherData?.txId : ''});
  }


  printReceipt = () => {
    this.spinner.show();
    this.resultService.print(this.receiptToPrint).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
      next: this.handleissuerRes.bind(this),
      error: this.handleIssuerError.bind(this)
    });
  }

  handleissuerRes = (res: any) => {
    this.spinner.hide();
    console.log('printReceipt: ', res);
  }

  handleIssuerError = (error: any) => {
    this.spinner.hide();
    this.errorHandler.errorHandler(error);
    console.log('printReceipt: ', error);
  }

}
