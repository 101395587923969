import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DetailComponent } from './detail/detail.component';
import { ResultComponent } from './result/result.component';
import { SigninComponent } from './signin/signin.component';
import { ChallangeTokenComponent } from './challange-token/challange-token/challange-token.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'signin',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'ct/:challengeToken',
    component: ChallangeTokenComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path:'detail/:id',
    component: DetailComponent
  },
  {
    path: 'result/:id',
    component: ResultComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
