import { Component, DestroyRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ISettings } from '../models/interfaces/setting';
import { StorageService } from '../services/storage.service';
import { LoginReq, LoginRes } from '../models/interfaces/login';
import { SigninService } from './service/signin.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { NgxSpinnerService } from 'ngx-spinner';
import { STORAGE } from '../models/enum/general';
import { EnumAccountContextType } from '../models/enum/context';
import { ErrorHandlerService } from '../services/error-handler.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {
  telephone: string = '';
  setting: ISettings = {};
  destroyRef = inject(DestroyRef);
  storage = STORAGE;

  constructor(private readonly router: Router,
    private readonly signinService: SigninService,
    private localStorageService: StorageService,
    private errorHandler: ErrorHandlerService,
    private spinner: NgxSpinnerService) {
    this.spinner.hide();
}

  login = () => {
    if (this.telephone) {
      this.setting = this.localStorageService.getStorage(this.storage.SETTINGS);
      if(this.setting) {
        this.spinner.show();
        const login: LoginReq = {
          identifier: this.telephone,
          challengeToken: this.setting?.challengeToken ? this.setting?.challengeToken : '',
          context: EnumAccountContextType.MERCHANT
        }
        this.signinService.login(login).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( {
          next: this.handleissuerRes.bind(this),
          error: this.handleIssuerError.bind(this)
        });
      } else {
       
        // this.router.navigateByUrl('home');
      }
    }
  }

  handleissuerRes = (loginRes: LoginRes) => {
    console.log(JSON.stringify(loginRes));
    if (loginRes) {
    this.setting.accessToken = loginRes.accessToken;
    this.setting.refreshToken = loginRes.refreshToken;
    this.setting.clientKey = loginRes.clientKey;
    this.localStorageService.setStorage(this.storage.SETTINGS, this.setting);
    this.spinner.hide();
    this.router.navigateByUrl('home');
    }
  }

  handleIssuerError = (error: any) => {
    this.errorHandler.errorHandler(error);
    this.spinner.hide();
  }

}
