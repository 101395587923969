<section class="flex flex-col justify-between min-h-full">
  <header class="bg-gray-300 w-100 p-4 flex justify-center">
    <img src="/assets/img/logo.png" alt="logo" class="h-8">
  </header>
  <ng-container>
    <div class="scanner-container">
      <fa-icon icon="qrcode" class="text-gray-300 text-8xl absolute"></fa-icon>
      <div class="scanner-square" [ngClass]="scannerEnabled ? 'active' : ''">
        <div class="scanner-bar"></div>
      </div>
      <div class="flex justify-center align-center relative">

        <zxing-scanner
          [enable]="scannerEnabled"
          [formats]="enabledFormats"
          [style]="{ width: '100%', height: '500px' }"
          (camerasFound)="camerasFoundHandler($event)"
          (camerasNotFound)="camerasNotFoundHandler($event)"
          (scanSuccess)="scanSuccessHandler($event)"
          (scanError)="scanErrorHandler($event)">
        </zxing-scanner>


      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!scannerEnabled else enabled">
    <button class="rounded-full bg-gray-200 p-3 m-4" (click)="toggleScanner()">
      <span class="uppercase text-xl">
        Scannerizza un qr code
      </span>
    </button>
  </ng-container>
  <ng-template #enabled>
    <button class="rounded-full bg-red-300 p-3 m-4" (click)="toggleScanner()">
      <fa-icon icon="xmark"></fa-icon>
    </button>
  </ng-template>
</section>
