<section class="flex flex-col justify-between">
  <section class="bg-gray-300 p-4 flex items-center gap-5">

    <a class="text-lg" [routerLink]="['/home']">  <fa-icon icon="arrow-left" class=" text-xl "></fa-icon> Lettura Buono</a>
  </section>

  <ng-container *ngIf="voucher$ | async as voucher">
    <div class="flex items-center flex-col">
    <!--<div class="bg-gray-100 px-8 py-24 flex justify-center w-full">
      <fa-icon icon="image" class="text-gray-300 text-7xl "></fa-icon>
    </div>
    <div class="h-20 w-20 bg-gray-300 rounded-full flex justify-center items-center -mt-10">
      <fa-icon icon="store" class="text-gray-500 text-3xl "></fa-icon>
    </div>-->
    <div class="card-header" [style.background-image]="'url('+ voucher.imageHeader + ')'">
      <img class="card-img" alt="User image" src="{{voucher.imageLogo}}">
    </div>
    <div class="flex flex-row justify-between items-center gap-2 w-full p-5">
     <div>
      <p> Scade: {{ voucher.extendedVoucherData!.expiry }}</p>
     </div>
     <p class="text-3xl">{{ voucher.extendedVoucherData!.amount | currency : voucher.extendedVoucherData!.currency!.toUpperCase() }}</p>
    </div>
    <div class="p-5">
      <p>{{ voucher.extendedVoucherData!.description }}</p>
    </div>
    <button class="rounded-full bg-gray-200 py-4 px-6 m-4" (click)="handleVoucherUse(voucher.voucherKey!)">
      <span class="uppercase text-xl">
        Usa buono cliente
      </span>
    </button>

  </div>
  </ng-container>
</section>
