export enum STATES {
    NEW = 'new',
    USED = 'used',
    EXPIRED = 'expired',
    ASSIGNED = 'assigned'
  }
  
export enum STORAGE {
    SETTINGS = 'SETTINGS'
  }
    