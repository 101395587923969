import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { LoginReq, LoginRes } from 'src/app/models/interfaces/login';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(private http: HttpClient,) { }

  login(loginReq : LoginReq): Observable<LoginRes> {
   
    const jsonObjectT: JSON = loginReq as unknown as JSON;
      return this.http.post<LoginRes>(environment.api.signin, jsonObjectT).pipe(map((response: LoginRes | any) => {
        if (response) {
          return response;
        }
        return null;
      }));
  }
}
