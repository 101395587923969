
export class Utils {

    public static interpolationTemplate = (tpl: any, args: any) => tpl.replace(/\${(\w+)}/g, (_: any, v: any) => args[v]);
  
    public static yyyymmddToDate(rptDate: number) {
      const dateStr = rptDate.toString();
      return new Date([dateStr.slice(0, 4), dateStr.slice(4, 6), dateStr.slice(6, 8)].join('-'));
    }

    public static formatDate(date: Date): string {
      // Get day, month, year, hours, and minutes from the Date object
      const day = ('0' + date.getDate()).slice(-2); // Get the day and add leading zero if necessary
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get the month and add leading zero if necessary
      const year = date.getFullYear(); // Get the full year
      const hours = ('0' + date.getHours()).slice(-2); // Get the hours and add leading zero if necessary
      const minutes = ('0' + date.getMinutes()).slice(-2); // Get the minutes and add leading zero if necessary
    
      // Combine day, month, year, hours, and minutes into the desired format
      const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    
      return formattedDate;
    }

    public static formatAmount(amount: string): string {
      // Parse the input string to a floating point number
      const parsedAmount = parseFloat(amount.replace(',', '.'));
    
      // Check if the parsedAmount is a valid number
      if (!isNaN(parsedAmount)) {
        // Convert the number to a string with exactly 2 decimal places
        let formattedAmount = parsedAmount.toFixed(2);
        
        // Replace the decimal separator "." with ","
        formattedAmount = formattedAmount.replace('.', ',');
    
        // Check if the formattedAmount has the decimal separator
        if (formattedAmount.indexOf(',') === -1) {
          // If it doesn't have a decimal separator, add ",00" to the end
          formattedAmount += ',00';
        }
        
        // If it has a decimal separator, return the formatted amount as is
        return formattedAmount;
      }
      
      // If the input string is not a valid number, return it as is
      return amount;
    }


    public static formatRow(initialPart: string, value: string): string {
      const totalLength = 24; // Total length of the string
      const spacesNeeded = totalLength - (initialPart.length + value.length); // Calculate the number of spaces needed
      const spaces = ' '.repeat(spacesNeeded > 0 ? spacesNeeded : 0); // Generate the required number of spaces
    
      // Construct the total string with the calculated spaces
      const totalString = `${initialPart}${spaces}${value}`;
      
      return totalString;
    }

  }
  
export const receipt = {
  "data": [
    {"op": "style", "option": "space", "value": 1},
    {"op": "style", "option": "align", "value": "center"},
    {"op": "style", "option": "bold", "value": true},
    {"op": "text", "value": "LIS Holding S.p.A."},
    {"op": "cmd", "type": "eol"},
    {"op": "style", "option": "align", "value": "left"},
    {"op": "style", "option": "bold", "value": false},
    {"op": "text", "value": "    Negozio di test"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "Via Achille Campanile,73"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "     00143 Roma (RM)"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "     "},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "Data:   ${date}"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "Pasto Completo:   100,00"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "------------------------"},
    {"op": "cmd", "type": "eol"},
    {"op": "style", "option": "bold", "value": true},
    {"op": "text", "value": "TOTALE EUR        100,00"},
    {"op": "style", "option": "bold", "value": false},
    {"op": "text", "value": "${description} EUR"},
    {"op": "text", "value": "CONTANTI EUR"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "    "},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "  Arrivederci e Grazie"},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "    "},
    {"op": "cmd", "type": "eol"},
    {"op": "text", "value": "TrxId: ${txId}"},
    {"op": "cmd", "type": "eol"},
    {"op": "cmd", "part": false, "type": "cut"}
  ]
};