import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { STORAGE } from 'src/app/models/enum/general';
import { ISettings } from 'src/app/models/interfaces/setting';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-challange-token',
  templateUrl: './challange-token.component.html',
  styleUrls: ['./challange-token.component.scss']
})
export class ChallangeTokenComponent {
  challengeToken: string | null = '';
  destroyRef = inject(DestroyRef);
  storgae = STORAGE;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: StorageService,
    private readonly spinner: NgxSpinnerService
  ) {
    this.spinner.show();
  }

  /**
   * @memberof ChallangeTokenComponent
   * @function
   * @returns {void}
   * @description  This hook subscribes to route parameters, extracting the 'challengeToken' from the URL.
   * If a valid 'challengeToken' is present, it stores the token in the local storage for persistence and redirects the user to the sign-in page. If no 'challengeToken' is found, it displays an error toast message
   * and redirects the user to the landing page.
   */
  ngAfterViewInit() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.challengeToken = params['challengeToken'];
      if(this.challengeToken) {
        let setting : ISettings = {
          challengeToken: this.challengeToken
        };
        this.localStorageService.setStorage(this.storgae.SETTINGS, setting);
        this.router.navigateByUrl('signin');
      } else {
        
      }
    });
}
}
